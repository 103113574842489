// @ts-nocheck
import React, { useState } from "react";
import { createContext, useContext } from "react";
import { staffAPI } from "../helpers/api";
import { config } from "../config";

export const AuthContext = createContext();
export const UserContext = createContext();

export function AuthProvider(props) {
  const [user, setUser] = useState(null);
  const login = async (token) => {
    try {
      const { data: auth } = await staffAPI.post("auth/token", {
        token,
        grant_type: "magic_token",
      });
      const storage = JSON.stringify(auth);

      localStorage.setItem(config.localStorageKey, storage);
      setUser({ auth, user: auth.access });
    } catch (e) {
      console.log(e);
    }
  }; // make a login request

  const refresh = async () => {
    try {
      const { data: auth } = await staffAPI.post("auth/token", {
        token: `Bearer ${user.auth.refreshToken}`,
        grant_type: "refresh_token",
      });
      setUser({ auth, user: auth.access });
      localStorage.setItem(config.localStorageKey, JSON.stringify(auth));
      return auth;
    } catch (e) {
      console.log(e);
    }
  }; // make a login request

  const loginRequest = async (email) => staffAPI.post("auth/token", { email });

  const register = () => {}; // register the user

  const logout = () => {
    localStorage.removeItem(config.localStorageKey);
    setUser(null);
  };

  const { Provider } = AuthContext;
  const data = { user };

  if (!user) {
    const auth = JSON.parse(localStorage.getItem(config.localStorageKey));
    auth && setUser({ auth, user: auth.access });
  }

  const decompose = (input) => {
    const [header, payload, _signature] = input.split(".");

    return {
      header: JSON.parse(atob(header)),
      payload: JSON.parse(atob(payload)),
      _signature: _signature,
    };
  };

  const getTime = (x) => {
    console.log(x);
    const d = new Date(x * 1000);
    return d.getTime();
  }

  const isValid = ({ exp }) => getTime(exp) > Date.now();

  if (user) {
    staffAPI.interceptors.request.use(async (request) => {
      if (!request) return;
      // un authed
      if (["login", "accessToken", 'auth/token'].includes(request.url)) {
        return request;
      }
      const { auth } = user;

      request.headers.common.authorizaton = `Bearer ${user.auth.accessToken}`;

      const accessToken = decompose(auth.accessToken);
      const refreshToken = decompose(auth.refreshToken);

      if (!isValid(refreshToken.payload)) {
        console.log('refresh token invalid', refreshToken.payload)
        // logout();
        // return false;
      }

      if (!isValid(accessToken.payload)) {
        const refreshed = await refresh();
        request.headers.common.authorizaton = `Bearer ${refreshed.auth.accessToken}`;
      }

      return request;
    });
  }
  const authenticatedAPI = user && staffAPI;

  return (
    <Provider
      value={{
        data,
        login,
        logout,
        register,
        loginRequest,
        refresh,
        authenticatedAPI,
      }}
      {...props}
    />
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export const UserProvider = (props) => (
  <UserContext.Provider value={useAuth().data.user} {...props} />
);

export const useUser = () => React.useContext(UserContext);
