// @ts-nocheck
import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import "./App.css";

import { AuthProvider, UserProvider, useUser } from "./Contexts/auth";
// import { AuthContext, useAuth , AuthProvider} from "./Contexts/auth";

const AuthenticatedApp = React.lazy(() => import("./Authenticated.app"));
const UnAuthenticatedApp = React.lazy(() => import("./UnAuthenticated.app"));

function Content() {
  const { user } = useUser() || { user: null };
  return (
    <Router>
      {user && user.email ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
    </Router>
  );
}
function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <Content />
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
